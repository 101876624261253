<script setup lang="ts">
import { getTranslatedProperty, getCategoryRoute } from "@shopware-pwa/helpers-next";

const { formatLink } = useInternationalization();
const { navigationElements } = useNavigation({ type: "footer-navigation" });

const { data: maasCatalogList } = await useMaasCatalogList();
const catalogs = computed(() => {
  return maasCatalogList?.value?.catalogs ? maasCatalogList.value?.catalogs : [];
})

const { data: maasStoresList } = await useMaasStoresList();
const stores = computed(() => {
  return maasStoresList?.value?.stores ? maasStoresList.value.stores : [];
})
</script>

<template>
    <footer class="mt-18 xl:mt-36 bg-maas-background-grey-light pb-24">
      <div class="s-gutter border-t-px">
        <div class="c-width-small flex flex-col md:flex-row gap-x-12 gap-y-8 py-12 justify-between">
          <div class="flex items-center gap-x-4">
            <img src="@/assets/image/icon-lock.svg" loading="lazy" width="28" height="38" alt="Sicher einkaufen" />
            <h3 class="text-h5 text-maas-typography-headline-primary">Sicher einkaufen</h3>
          </div>
          <div class="flex items-center gap-x-4">
            <img src="@/assets/image/icon-invoice.svg" loading="lazy" width="37" height="36" alt="Kauf auf Rechnung" />
            <h3 class="text-h5 text-maas-typography-headline-primary">Kauf auf Rechnung</h3>
          </div>
          <div class="flex items-center gap-x-4">
            <img src="@/assets/image/icon-package.svg" loading="lazy" width="33" height="36" alt="Kostenfreie Retoure" />
            <h3 class="text-h5 text-maas-typography-headline-primary">Kostenfreie Retoure</h3>
          </div>
        </div>
      </div>
      <div class="bg-maas-background-primary text-maas-typography-text-light">
        <div class="s-gutter">
          <div
            class="c-width-narrow flex flex-col lg:flex-row gap-x-16 gap-y-12 2xl:gap-x-24 pt-12 pb-16 justify-between"
          >
            <div class="w-full">
              <h3 class="mb-6 text-h4">Newsletter abonnieren</h3>
              <p>
                Sie erhalten Modetipps und erfahren regelmäßig alles über unsere neuen Kollektionen und Kataloge, über
                Lagerverkäufe, Aktionen und Sonderangebote
              </p>
              <SharedFormCompositionNewsletter class="w-full mt-4" />
            </div>

            <div class="w-fit xl:shrink-0">
              <h3 class="mb-6 text-h4">Telefonisch bestellen</h3>
              <p>Maas-Bestelltelefon kostenfrei aus Deutschland</p>
              <p class="my-4 text-h2 whitespace-nowrap"><a href="tel:08000701200">0800 / 0701200</a></p>
              <p>
                <span class="inline-block whitespace-nowrap">Montag bis Freitag 8 bis 18 Uhr,&nbsp;</span>
                <span class="inline-block whitespace-nowrap">Samstag 9 bis 13 Uhr</span>
              </p>
            </div>

            <div
              v-if="maasStoresList && stores"
              class="@container/storenavigation w-full"
            >
              <h3 class="mb-6 text-h4">Filiale besuchen</h3>
              <p>Finden Sie den nächsten Maas Laden in Ihrer Nähe</p>
              <ul
                class="@2xs/storenavigation:gap-x-12 @2xs/storenavigation:columns-2 @sm/storenavigation:columns-3 @lg/storenavigation:columns-4 mt-4 w-fit"
              >
                <template v-for="store in stores" :key="store.id">
                  <li class="mb-2 ws-nowrap">
                    <NuxtLink
                      :to="formatLink('/Filialen/Standorte/' + store.slug)"
                      class="underline underline-offset-3 hover:decoration-none"
                    >
                      {{ store.city }} <template v-if="store.type === 'Outlet'">Outlet</template>
                    </NuxtLink>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="s-gutter">
        <div class="c-width-default mt-16">
          <div class="flex sm:grid xl:flex sm:grid-cols-2 flex-col lg:flex-row gap-x-12 lg:gap-x-24 gap-y-12 justify-between">
            <div class="sm:order-1 lg:order-3 xl:order-0">
            <h3 class="text-h4 text-maas-typography-headline-primary mb-6"><template v-if="catalogs.length > 1">Unsere Kataloge</template><template v-else>Unser Katalog</template></h3>
              <div class="relative w-[240px] mt-8 mb-12">
                <template v-for="(catalog, index) in catalogs" :key="catalog.id">
                  <NuxtLink :to="formatLink('/katalog/' + catalog.slug)">
                    <SharedCmsImage
                      :src="catalog.cover.url"
                      :crop="catalog.crop"
                      :width="120"
                      :alt="'Katalog: ' + catalog.title"
                      :class-img="{
                        'w-[120px] h-[160px] shadow-md': true,

                        // 2 catalogues
                        'absolute top-[25px] left-[110px] rotate-[10deg]': catalogs.length === 2 && index === 1,

                        // 3 catalogues
                        'rotate-[-12deg]': catalogs.length === 3 && index === 0,
                        'absolute rotate-[3deg] top-[5px] left-[55px]': catalogs.length === 3 && index === 1,
                        'absolute top-[25px] left-[110px] rotate-[15deg]': catalogs.length === 3 && index === 2,
                      }"
                    />
                  </NuxtLink>
                </template>
              </div>
            </div>

            <div class="grid grid-cols-2 col-span-2 lg:flex flex-col md:flex-row gap-x-12 lg:gap-x-24 2xl:gap-x-24 gap-y-12 grow-1 xl:justify-evenly">
              <template v-for="navigationElement in navigationElements" :key="navigationElement.id">
                <div v-if="navigationElement.childCount > 0" class="grid lg:flex xl:flex-col gap-12" :class="{
                  'col-span-2 gap-x-12 sm:grid-cols-2': navigationElement.name === 'Kontakt'
                }">
                  <div>
                    <h3 class="text-h4 text-maas-typography-headline-primary mb-6">
                      {{ getTranslatedProperty(navigationElement, "name") }}
                    </h3>
                    <ul
                      class="mt-4 w-fit"
                      :class="{
                        'columns-2 gap-12': (navigationElement?.children?.length ?? 0) > 6
                      }"
                    >
                      <li
                        v-for="navigationChild in navigationElement.children"
                        :key="navigationChild.id"
                        class="mb-2"
                        :class="{
                          'max-w-[130px]': (navigationElement?.children?.length ?? 0)> 6
                        }"
                      >
                        <NuxtLink
                          :target="navigationChild.externalLink || navigationChild.linkNewTab ? '_blank' : ''"
                          :to="navigationChild.customFields?.seoUrl ? formatLink('/' + navigationChild.customFields?.seoUrl) : formatLink(getCategoryRoute(navigationChild))"
                          class="text-maas-typography-navigation-grey-normal underline underline-offset-3 hover:decoration-none"
                        >
                          {{ getTranslatedProperty(navigationChild, "name") }}
                        </NuxtLink>
                      </li>
                    </ul>
                  </div>

                  <div v-if="navigationElement.name === 'Kontakt'">
                    <h3 class="text-h4 text-maas-typography-headline-primary whitespace-nowrap">Social Media</h3>
                    <ul class="mt-6 flex gap-7">
                      <li>
                        <a href="https://www.facebook.com/maasnatur" target="_blank">
                          <img src="@/assets/image/icon-facebook.svg" loading="lazy" width="13" height="24" alt="facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/maas.natur/?hl=de" target="_blank">
                          <img src="@/assets/image/icon-instagram.svg" loading="lazy" width="22" height="24" alt="instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.pinterest.de/maasnatur/" target="_blank">
                          <img src="@/assets/image/icon-pinterest.svg" loading="lazy" width="18" height="24" alt="pinterest" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </div>

            <div class="order-2 grow-0 shrink-0">
              <h3 class="text-h4 text-maas-typography-headline-primary mb-6">Geprüfter Shop</h3>
              <div class="flex flex-wrap lg:flex-none flex-row lg:flex-row xl:flex-col gap-12">
                <div class="max-w-[220px] xl:max-w-[180px]">
                  <img class="block max-h-16 max-w-none" src="@/assets/image/logo-bevh-certified.png" loading="lazy" width="144" height="60" alt="EHI zertifiziert" />
                  <p class="text-maas-typography-text-grey-medium mt-4 text-small leading-normal">
                    Durch das Kölner EHI Retail Institute zertifizierter Online-Shop
                  </p>
                </div>
                <div class="max-w-[220px] xl:max-w-[180px]">
                  <img class="block max-h-16 max-w-none" src="@/assets/image/logo-trust-ecommerce-europe.png" loading="lazy" width="60" height="60" alt="European trust mark" />
                  <p class="text-maas-typography-text-grey-medium mt-4 text-small leading-normal">
                    Gütesiegel für Einhaltung europäischer Kriterien für sicheres Online-Shopping
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>
